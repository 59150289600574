import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledPageContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loan-type-buttons-container {
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
    }
  }

  .cta__text {
    text-align: center;
    margin: 0 20px;
  }

  a {
    // color: var(--gray);
    text-decoration: none;
    // display: block;
    padding: 9px 15px;

    &:hover {
      color: var(--blue);
    }
    @media (max-width: 800px) {
    }

    &.loan-type-button {
      border: 1px solid var(--blue);
      color: var(--blue);
      border-radius: 3px;
      margin: 0 10px;
      text-align: center;

      &:hover {
        background: var(--blue);
        color: white;
        border-color: tranparent;
      }

      @media (max-width: 800px) {
        margin: 10px 0;
      }
    }
  }
`;

export default function HomePage({ data }) {
  return (
    <StyledPageContainer>
      <h2 className="cta__text">Please select a type of loan to calculate</h2>

      <section className="loan-type-buttons-container">
        <Link to="/consumer-loan" className="loan-type-button">
          Consumer Loan
        </Link>
        <Link to="/vehicle-purchase" className="loan-type-button">
          Vehicle Purchase
        </Link>
        <Link to="/vehicle-lease" className="loan-type-button">
          Vehicle Lease
        </Link>
      </section>
    </StyledPageContainer>
  );
}
